var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.playCaremas, function (cam, index) {
      return _c("revo-video-modal", {
        key: index,
        ref: "ref-" + (cam.id || cam.streamId),
        refInFor: true,
        attrs: {
          cameraInfo: cam,
          "is-live": false,
          cameraId: cam.id,
          streamId: cam.streamId,
          isSyncVideo: true,
          index: index,
          noPlayTimer:
            _vm.camerasObj[cam.streamId] &&
            _vm.camerasObj[cam.streamId].noPlayTimer,
          isInfreeze:
            _vm.camerasObj[cam.streamId] &&
            _vm.camerasObj[cam.streamId].inFreeze,
          videoUrl:
            _vm.camerasObj[cam.streamId] && _vm.camerasObj[cam.streamId].url,
          position: _vm.setTrackCameraPosition(index),
          seekTime:
            _vm.camerasObj[cam.streamId] &&
            _vm.camerasObj[cam.streamId].seekTime,
        },
        on: {
          handleReady: function ($event) {
            return _vm.handleReady(cam, $event)
          },
          handleError: _vm.handleError,
          handleEnded: _vm.handleEnded,
          handleShowAll: _vm.handleShowAll,
          handlePlayAll: _vm.handlePlayAll,
          handlePlay: _vm.handlePlay,
          isErrorPlay: _vm.isErrorPlay,
          closeModalCamera: _vm.closeModalCamera,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }