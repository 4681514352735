<!--
 * @Auth: linjituan
 * @Date: 2022-12-02 10:32:53
 * @LastEditors: wuchangsen 1910276073@qq.com
 * @LastEditTime: 2023-07-05 11:52:18
-->
<template>
  <div>
    <revo-video-modal
      v-for="(cam, index) in playCaremas"
      :ref="'ref-' + (cam.id || cam.streamId)"
      :key="index"
      :cameraInfo="cam"
      :is-live="false"
      @handleReady="handleReady(cam, $event)"
      @handleError="handleError"
      @handleEnded="handleEnded"
      @handleShowAll="handleShowAll"
      @handlePlayAll="handlePlayAll"
      @handlePlay="handlePlay"
      @isErrorPlay="isErrorPlay"
      :cameraId="cam.id"
      :streamId="cam.streamId"
      :isSyncVideo="true"
      :index="index"
      :noPlayTimer="camerasObj[cam.streamId] && camerasObj[cam.streamId].noPlayTimer"
      :isInfreeze="camerasObj[cam.streamId] && camerasObj[cam.streamId].inFreeze"
      :videoUrl="camerasObj[cam.streamId] && camerasObj[cam.streamId].url"
      @closeModalCamera="closeModalCamera"
      :position="setTrackCameraPosition(index)"
      :seekTime="camerasObj[cam.streamId] && camerasObj[cam.streamId].seekTime"
    />
  </div>
</template>

<script>
import MonitorCameraBase from '../../monitorForm/vehicle/funcList/modules/MonitorCameraBase'
import RevoVideoModal from '../../monitorForm/vehicle/funcList/modules/RevoVideoModal'
// import { videoHistoryQuery } from '@/api/iot/vehicleAbilityCamera'
import { unfreeze, checkFileType } from '@/api/iot/vehicleAbilityCamera'
import moment from 'moment'

export default {
  mixins: [MonitorCameraBase],
  components: {
    RevoVideoModal
  },
  props: {
    // params: {
    //   type: Object,
    //   default: () => {}
    // },
    camerasObj: {
      type: Object,
      default: () => {}
    },
    objectInfo: {
      type: Object,
      default: () => {}
    },
    queryCaremas: {
      type: Array,
      default: () => []
    },
    currentSpeed: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      queryParam: {
        streamId: null,
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 200,
        pageObj: {}
      },
      mainObj: {
        playDate: ''
      },
      playCaremas: [],
      playInterval: {},
      freezeTimeOut: {},
      isMainFlag: false,
      mainStearmId: '',
      caremaIdMap: {},
      // 默认初始化跳点播放已完成，避免初始轨迹跳点不播放 (handleTrackChange)
      isJumpPlayInit: true
    }
  },
  watch: {
    objectInfo(val) {
      if (val) {
        this.showAllCamera('update')
      }
    },
    queryCaremas(val) {
      if (val.length > 0) {
        this.showAllCamera(val)
      }
    }
  },
  mounted() {
    this.showAllCamera(this.queryCaremas)
  },
  methods: {
    closeModalCamera(cameraId, StreamId) {
      console.log('closeModalCameraChild', arguments)
      console.log('closeModalCameraChild', this.cameras)
      this.playCaremas = this.playCaremas.map((c) => {
        if (c.id && c.id === cameraId) {
          this.$set(c, 'cameraVisible', false)
        } else if (c.streamId === StreamId) {
          this.$set(c, 'cameraVisible', false)
        }
        return c
      })
    },
    showAllCamera(camerasArr) {
      const cameras = camerasArr.length > 0 ? camerasArr : JSON.parse(JSON.stringify(this.objectInfo.cameras))
      this.caremaIdMap = {}
      this.playCaremas = cameras.map((c) => {
        this.caremaIdMap[c.streamId] = c.id || c.streamId
        // if (c.cameraVisible === undefined) {
        this.$set(c, 'cameraVisible', true)
        // }
        return c
      })
      console.log('showAllCamera *********', this.playCaremas)
    },
    setVedioUrlAndPlayStatus(streamId, record, type, playDate) {
      if (this.camerasObj[streamId].url === record.url) {
        console.log('当前播放地址与设置的地址相同')
        return
      }
      console.log('设置' + this.camerasObj[streamId].cameraName + '播放地址 ** url:', record.url)
      console.log('设置播放地址 ** camerasObj:', this.camerasObj)
      this.$set(this.camerasObj[streamId], 'url', record.url)
      this.$set(this.camerasObj[streamId], 'keepBeforePlayTime', new Date().getTime())
      this.$set(this.camerasObj[streamId], 'playTime', moment(playDate))
      if (type !== 'doSend') {
        this.$emit('vedioReadyToPlay', this.statusInfo, this.jumpVal)
      }
      if (this.jumpVal && (this.statusInfo.status === 2 || this.statusInfo.status === 0)) {
        this.camerasObj[streamId].jumpWithStop = true
      }
      // 视频预先解冻逻辑，当前播放视频上一个及下一个
      if (this.camerasObj[streamId].lastObj && this.camerasObj[streamId].lastObj.isFreeze) {
        // 如果下个播放的视频需要解冻，则提前进行静默解冻操作
        this.checkVideoType(this.camerasObj[streamId].lastObj, streamId, true, type, playDate)
      }
    },
    isErrorPlay(type, streamId) {
      console.log('isErrorPlay', type, 'xx', streamId)
      const keepBeforePlayTime = this.camerasObj[streamId].keepBeforePlayTime
      if (type === 'end' && keepBeforePlayTime) {
        const nt = new Date().getTime()
        if (nt - keepBeforePlayTime > 3000) {
          this.camerasObj[streamId].playTime = this.camerasObj[streamId].playTime - (nt - keepBeforePlayTime)
        }
      }
    },
    handleReady(record, duration) {
      console.log('handleReady ** play', record)
      // this.$set(this.camerasObj[record.streamId], 'duration', duration)
      if (this.camerasObj[record.streamId].jumpWithStop) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs['ref-' + record.id] && this.$refs['ref-' + record.id][0].pause()
            this.clearPlayInterval(record.streamId)
            this.camerasObj[record.streamId].jumpWithStop = false
          }, 0)
        })
      } else {
        this.handleSpeedChange(this.currentSpeed)
      }
    },
    handleError(e, streamId) {
      console.log('handleError ******', e)
      console.log('handleError ****** streamId', this.camerasObj[streamId])
      let arr = []
      if (this.camerasObj[streamId] && this.camerasObj[streamId].videoList) {
        arr = this.camerasObj[streamId].videoList
      }
      streamId &&
        this.$emit(
          'loadHistoryList',
          streamId,
          arr,
          this.camerasObj[streamId].cameraName,
          this.camerasObj[streamId].canPlay
        )
      console.log('handleError ****** streamId after', this.camerasObj[streamId])
    },
    playVideoEvent(streamId, playDate, type) {
      // 播放对应窗口对应时间视频，并设置跳转播放时间
      console.log('playVideoEvent ******', arguments)
      const nowList = this.camerasObj[streamId] ? this.camerasObj[streamId].videoList : []
      const playTime = moment(playDate)
      let isFindVideo = false
      for (const item of nowList) {
        const startTime = moment(item.startTime)
        const endTime = moment(item.endTime)
        // 视频已加载成功才能取到视频实际时长
        const duration = this.$refs['ref-' + this.caremaIdMap[streamId]][0].getDuration()
        if (playTime >= startTime && playTime < endTime) {
          // 当找到对应视频时停止继续查找并设置对应播放参数
          if (!this.isMainFlag) {
            this.isMainFlag = true
            this.mainStearmId = streamId
          }
          const seekTime = (playTime - startTime) / 1000
          console.log(`startTime=${startTime.format('YYYY-MM-DD hh:mm:ss')},endTime=${endTime.format('YYYY-MM-DD hh:mm:ss')},playTime=,endTime=${playTime.format('YYYY-MM-DD hh:mm:ss')}, seekTime=${seekTime},duration=${duration}`)
          // 当出现seekTime > duration时，说明实际视频总时长比结束-开始的时间差来得短
          // 当出现这个现象时，视频会不停地回跳，说明视频丢失
          if (duration && seekTime > duration && !this.isResetTrackPlay) {
            // bug： 如果上次视频已播放过，但轨迹又初始化了，那么就会跳过播放视频了
            // 增加一个判断：是否重置了轨迹播放
            this.$set(this.camerasObj[streamId], 'playTime', playTime)
            this.$set(this.camerasObj[streamId], 'noPlayTimer', true)
          } else {
            isFindVideo = true
            this.isResetTrackPlay = false
            this.$set(this.camerasObj[streamId], 'seekTime', seekTime)
            this.$set(this.camerasObj[streamId], 'endTime', item.endTime)
            this.$set(this.camerasObj[streamId], 'startTime', item.startTime)
            this.$set(this.camerasObj[streamId], 'noPlayTimer', false)
            // 视频被冻结，同时视频没有在解冻中
            if (item.isFreeze && !this.camerasObj[streamId].inFreeze) {
              this.inFreeze = true
              console.log(`准备解冻,inFreeze=${this.inFreeze},time=${Math.random()}`)
              this.checkVideoType(item, streamId, false, type, playDate, this.camerasObj[streamId].cameraName)
            } else {
              this.setVedioUrlAndPlayStatus(streamId, item, type, playDate)
              this.setPlayIntervalEvent(streamId)
            }
          }
          break
        } else {
          // 下一个播放地址参数
          this.$set(this.camerasObj[streamId], 'lastObj', item)
          // console.log('last vedio console **', this.camerasObj[streamId])
        }
      }

      if (!isFindVideo) {
        this.$set(this.camerasObj[streamId], 'playTime', playTime)
        this.$set(this.camerasObj[streamId], 'noPlayTimer', true)
        this.$set(this.camerasObj[streamId], 'url', '')
        if (type !== 'doSend') {
          this.$emit('vedioReadyToPlay', this.statusInfo, this.jumpVal)
        }
        this.$set(this.camerasObj[streamId], 'lastObj', {})
      }
    },
    targetVideo(streamId, time) {
      // 切换视频播放
      console.log('targetVideo ******', arguments)
      this.playVideoEvent(streamId, time)
      // 通知轨迹播放当前为切换视频
      this.$emit('handleTargeVideo', time)
    },
    setPlayIntervalEvent(streamId) {
      // 设置播放定时任务，当到达当前播放视频的结束时间时，播放下个视频
      if (this.playInterval[streamId]) {
        return
      }
      this.playInterval[streamId] = setInterval(() => {
        const obj = this.camerasObj[streamId]
        const endTime = moment(obj.endTime)
        if (obj.playTime) {
          if (obj.playTime < endTime) {
            obj.playTime += 1000 * this.currentSpeed
            // obj.playTime = obj.playTime.add(1000 * this.currentSpeed, 'ms')
            console.log(`视频结束时间B=${endTime}, 预计播放时间=${obj.playTime}`)
            // console.log('interval *** ', moment(parseInt(obj.playTime)).format('YYYY/MM/DD hh:mm:ss'))
          } else {
            // 播放下个视频时操作
            this.clearPlayInterval(streamId)
            if (obj.lastObj) {
              console.log('interval *** end')
              this.targetVideo(streamId, obj.lastObj.startTime)
            }
          }
        }
      }, 1000)
    },
    trackPlay(playDate, status, oldStatus, inFreeze) {
      // 播放事件
      console.log('trackPlay - data', this.camerasObj)
      console.log('trackPlay - oldStatus', arguments)
      console.log('playCaremas', this.playCaremas)
      this.statusInfo = {
        status,
        oldStatus
      }
      this.trackPosition = null
      this.trackStatus = null
      this.jumpVal = null
      for (const item of this.playCaremas) {
        if (item.streamId) {
          this.camerasObj[item.streamId].nowCheckTime = null
        }
      }
      if (status === 1 && oldStatus === 0) {
        // 初始状态
        if (this.isJumpInit) {
          this.playEvent()
        } else {
          this.toPlay(playDate)
        }
      } else if (status === 1 && oldStatus > 0) {
        // 暂停后恢复播放
        if (inFreeze) {
          this.toPlay(playDate)
        } else {
          this.playEvent()
        }
      } else if (status === 2) {
        this.pauseEvent()
      }
    },
    trackJumpPlay(playDate, val, status, inFreeze) {
      // 拉到播放条进行跳转播放
      console.log('trackJumpPlay - playDate', arguments)
      if (this.checkDuplicateTime(playDate)) {
        // 重复跳点时不继续操作
        console.log('trackJumpPlay 当前为重复跳点，时间：' + playDate)
        return
      }
      if (inFreeze) {
        return
      }
      this.isJumpPlayInit = false
      for (const item of this.playCaremas) {
        if (item.streamId) {
          this.camerasObj[item.streamId].inFreeze = false
        }
      }
      this.jumpVal = val
      this.statusInfo = {
        status
      }
      this.checkPlayStatus(playDate, status)
    },
    toPlay(playDate, type, streamId) {
      console.log('toplay *********', arguments)
      if (streamId) {
        this.playVideoEvent(streamId, playDate, type)
      } else {
        for (const item of this.playCaremas) {
          if (item.streamId && item.cameraVisible && this.camerasObj[item.streamId].canPlay) {
            this.playVideoEvent(item.streamId, playDate, type)
          }
        }
      }
    },
    pauseEvent() {
      for (const item of this.playCaremas) {
        if (item.cameraVisible) {
          this.$refs['ref-' + (item.id || item.streamId)] && this.$refs['ref-' + (item.id || item.streamId)][0].pause()
          this.clearPlayInterval(item.streamId)
        }
      }
      this.$emit('vedioReadyToPlay', this.statusInfo, this.jumpVal)
    },
    playEvent() {
      console.log('playEvent ****')
      for (const item of this.playCaremas) {
        if (item.cameraVisible) {
          setTimeout(() => {
            this.$refs['ref-' + (item.id || item.streamId)] && this.$refs['ref-' + (item.id || item.streamId)][0].play()
            this.setPlayIntervalEvent(item.streamId)
          }, 100)
        }
      }
      this.$emit('vedioReadyToPlay', this.statusInfo, this.jumpVal)
    },
    clearPlayInterval(streamId) {
      this.playInterval[streamId] && clearInterval(this.playInterval[streamId])
      this.playInterval[streamId] = null
    },
    checkPlayStatus(playDate, status) {
      // 检测跳转播放时是否要切换当前视频，还是只在当前视频做seekTime
      console.log('checkPlayStatus ****** status', status)
      const playTime = moment(playDate)
      for (const item of this.playCaremas) {
        if (
          item.cameraVisible &&
          this.camerasObj[item.streamId] &&
          this.camerasObj[item.streamId].canPlay &&
          this.camerasObj[item.streamId].videoList.length
        ) {
          const startTime = moment(this.camerasObj[item.streamId].startTime)
          const endTime = moment(this.camerasObj[item.streamId].endTime)
          this.clearPlayInterval(item.streamId)
          if (this.camerasObj[item.streamId].url && playTime > startTime && playTime < endTime) {
            this.isJumpInit = false
            this.$set(this.camerasObj[item.streamId], 'playTime', playTime)
            this.$refs['ref-' + (item.id || item.streamId)][0].pause()
            setTimeout(() => {
              const seekTime = (playTime - startTime) / 1000
              this.$refs['ref-' + (item.id || item.streamId)][0].seek(seekTime)
              if (status === 1) {
                this.$refs['ref-' + (item.id || item.streamId)][0].play()
              } else {
                this.$refs['ref-' + (item.id || item.streamId)][0].pause()
              }
              this.setPlayIntervalEvent(item.streamId)
            }, 100)
          } else {
            this.isJumpInit = true
            this.toPlay(playDate, null, item.streamId)
          }
        }
      }
      this.isJumpPlayInit = true
    },
    checkVideoType(record, streamId, preUnfreeze, type, playDate, cameraName) {
      console.log('checkVideoType *****', arguments)
      const that = this
      const mPlayDate = moment(playDate)

      // 上下两个时间差小于3秒，则不检查文件类型，目的是啥？
      if (that.camerasObj[streamId].nowCheckTime) {
        const mNowCheckTime = moment(that.camerasObj[streamId].nowCheckTime)
        if (mPlayDate - mNowCheckTime > -3000 && mPlayDate - mNowCheckTime < 3000) {
          return
        }
      }
      that.camerasObj[streamId].nowCheckTime = playDate
      checkFileType({ objectName: record.ossObject }).then((res) => {
        if (res.data) {
          if (preUnfreeze) {
            that.unfreeze(record, streamId, preUnfreeze)
          } else {
            // that.camerasObj[streamId].inFreeze = true
            this.$set(this.camerasObj[streamId], 'inFreeze', true)
            const nPlayDate = moment(mPlayDate).format('YYYY-MM-DD HH:mm:ss')
            this.$emit('videoUnfreezeStart', null, nPlayDate, true)
            this.$confirm({
              content: `你在尝试查看7天前的历史视频，${cameraName}内容已被冻结归档，解冻需要时间`,
              okText: '开始解冻',
              zIndex: 99999,
              onOk() {
                return that.unfreeze(record, streamId, false, type, playDate)
              },
              onCancel() {
                console.log(`取消解冻,inFreeze=${this.inFreeze},time=${Math.random()}`)
                that.inFreeze = false
                that.camerasObj[streamId].inFreeze = false
              }
            })
          }
        } else {
          this.unfreezeItem(record, streamId)
          this.inFreeze = false
          console.log(`inFreeze=${this.inFreeze}`)
          // this.camerasObj[streamId].inFreeze = false
          this.$set(this.camerasObj[streamId], 'inFreeze', false)
          this.$set(record, 'isFreeze', false)
          // this.$set(this.camerasObj[streamId], 'url', record.url)
          this.setVedioUrlAndPlayStatus(streamId, record, type, playDate)
          this.setPlayIntervalEvent(streamId)
          this.$emit('videoUnfreezeEnd')
        }
      })
    },
    unfreezeItem(record, streamId) {
      // 此处为改变页面展示，转为播放按钮显示， this.$set(record, 'isFreeze', false) 用于播放事件里判断是否解冻
      for (const item of this.camerasObj[streamId].videoList) {
        if (item.id === record.id) {
          this.$set(item, 'isFreeze', false)
          // item.isFreeze = false
        }
      }
    },
    unfreeze(record, streamId, preUnfreeze, type, playDate) {
      unfreeze({ objectName: record.ossObject })
        .then((res) => {
          console.log('unfreeze *****', res.data.code)
          console.log(`解冻完成,inFreeze=${this.inFreeze},time=${Math.random()}`)
          if (res.data.code === 200) {
            // 若轮询获取成功状态清除定时器
            if (this.freezeTimeOut[record.id]) {
              clearTimeout(this.freezeTimeOut[record.id])
              this.freezeTimeOut[record.id] = null
            }
            this.unfreezeItem(record, streamId)
            this.$set(record, 'isFreeze', false)
            // 移动于此避免视频解冻但没恢复
            this.inFreeze = false
            // 设置解决完成，因为检查视频是否解冻时用到这个参数判断
            this.camerasObj[streamId].inFreeze = false
            console.log(`解冻成功 inFreeze=${this.inFreeze},time=${Math.random()}`)
            if (!preUnfreeze && this.trackPosition !== 0 && this.trackStatus !== 0) {
              this.$message.success('解冻成功', 3)
              // this.$set(this.camerasObj[streamId], 'url', record.url)
              this.setVedioUrlAndPlayStatus(streamId, record, type, playDate)
              this.setPlayIntervalEvent(streamId)
              this.$emit('videoUnfreezeEnd')
            } else if (this.trackPosition === 0 && this.trackStatus === 0) {
              this.trackPosition = null
              this.trackStatus = null
            }
            // this.$emit('vedioReadyToPlay', this.statusInfo, this.jumpVal)
          } else if (res.data.code === 1001) {
            // 解冻中时要进行轮询，更新状态
            this.freezeTimeOut[record.id] = setTimeout(() => {
              this.unfreeze(record, streamId, preUnfreeze, type, playDate)
            }, 1000)
          }
        })
        .catch((e) => {})
    },
    handleSpeedChange(speed) {
      for (const item of this.playCaremas) {
        if (item.cameraVisible) {
          this.$refs['ref-' + (item.id || item.streamId)] &&
            this.$refs['ref-' + (item.id || item.streamId)][0].setSpeed(speed)
        }
      }
    },
    handleTrackChange(time, position, status) {
      console.log('handleTrackChange *** in time:' + time + 'position:' + position)
      if (position === 0) {
        // 轨迹播放重置
        this.isResetTrackPlay = true
      }
      const isDuplicateTime = this.checkDuplicateTime(time)
      // 重复点位 | 解栋中 | 跳点初始化中 则忽略点位。。。
      if (isDuplicateTime || this.inFreeze || !this.isJumpPlayInit) {
        console.log(
          `isDuplicateTime=${isDuplicateTime},inFreeze=${this.inFreeze},isJumpPlayInit=${this.isJumpPlayInit}`
        )
        // 重复跳点或解冻中时不继续操作
        !this.inFreeze && console.log('handleTrackChange 当前为重复跳点，时间：' + time)
        return
      }
      this.trackPosition = position
      this.trackStatus = status
      if (position === 0 && (this.isEndPlay || status === 0)) {
        console.log('handleTrackChange *** return')
        this.isEndPlay && (this.isEndPlay = false)
        if (status === 0) {
          this.lastTime = null
          this.nowTime = null
        }
        return
      }
      const playTime = moment(time)
      for (const item of this.playCaremas) {
        if (
          item.cameraVisible &&
          this.camerasObj[item.streamId] &&
          this.camerasObj[item.streamId].canPlay &&
          this.camerasObj[item.streamId].videoList.length
        ) {
          const startTime = moment(this.camerasObj[item.streamId].startTime)
          const endTime = moment(this.camerasObj[item.streamId].endTime)
          const nowPlayTime = this.camerasObj[item.streamId].playTime
          if (nowPlayTime - playTime > 3000 || playTime - nowPlayTime > 3000) {
            console.log(`延迟${nowPlayTime - playTime}ms大于3秒,当前播放时间${time}`)
            if (playTime > startTime && playTime < endTime) {
              this.clearPlayInterval(item.streamId)
              this.$set(this.camerasObj[item.streamId], 'playTime', playTime)
              this.$refs['ref-' + (item.id || item.streamId)][0].pause()
              setTimeout(() => {
                const seekTime = (playTime - startTime) / 1000
                this.$refs['ref-' + (item.id || item.streamId)][0].seek(seekTime)
                this.$refs['ref-' + (item.id || item.streamId)][0].play()
                this.setPlayIntervalEvent(item.streamId)
              }, 100)
            } else {
              this.toPlay(time, 'doSend', item.streamId)
            }
          } else if (!this.camerasObj[item.streamId].url) {
            this.toPlay(time, 'doSend', item.streamId)
          }
        }
      }
    },
    clearAllPlayInterval() {
      for (const item of this.playCaremas) {
        if (item.cameraVisible) {
          this.clearPlayInterval(item.streamId)
        }
      }
    },
    handleShowAll() {
      console.log('handleShowAll *********')
      this.playCaremas = this.playCaremas.map((c) => {
        if (c.cameraVisible === false && this.camerasObj[c.streamId].canPlay && c.mainCamera !== 'yes') {
          const time = this.getCurrentPlayTime()
          this.playVideoEvent(c.streamId, time)
        }
        this.$set(c, 'cameraVisible', true)
        return c
      })
    },
    handlePlayAll() {
      console.log('handlePlayAll *********')
      for (const item of this.playCaremas) {
        if (item.cameraVisible && item.mainCamera !== 'yes') {
          this.$refs['ref-' + (item.id || item.streamId)] &&
            this.$refs['ref-' + (item.id || item.streamId)][0].trunSyncPlayOn()
          if (this.camerasObj[item.streamId] && !this.camerasObj[item.streamId].canPlay) {
            this.camerasObj[item.streamId].canPlay = true
            const time = this.getCurrentPlayTime()
            this.camerasObj[item.streamId].keepBeforePlayTime = ''
            this.playVideoEvent(item.streamId, time)
          }
        }
      }
    },
    getCurrentPlayTime() {
      if (this.camerasObj[this.mainStearmId]) {
        const mStartTime = moment(this.camerasObj[this.mainStearmId].startTime)
        const mPlayTime = mStartTime + this.camerasObj[this.mainStearmId].seekTime * 1000
        const playTime = moment(parseInt(mPlayTime)).format('YYYY-MM-DD HH:mm:ss')
        return playTime
      }
      return ''
    },
    handlePlay(streamId, syncPlayChecked) {
      console.log('handlePlay before', new Date().getTime())
      for (const item of this.playCaremas) {
        if (item.cameraVisible && item.streamId === streamId && this.camerasObj[item.streamId]) {
          this.camerasObj[item.streamId].canPlay = syncPlayChecked
          if (syncPlayChecked) {
            const time = this.getCurrentPlayTime()
            this.camerasObj[item.streamId].keepBeforePlayTime = ''
            this.playVideoEvent(item.streamId, time)
            console.log('handlePlay in', new Date().getTime())
          }
          break
        }
      }
    },
    handleTrackFinish() {
      // 当轨迹播放结束时
      console.log('handleTrackFinish ******')
      this.clearAllPlayInterval()
      this.pauseEvent()
      for (const item of this.playCaremas) {
        this.camerasObj[item.streamId].url = ''
        this.camerasObj[item.streamId].keepBeforePlayTime = ''
        this.$set(this.camerasObj[item.streamId], 'noPlayTimer', false)
      }
      this.isEndPlay = true
    },
    checkDuplicateTime(time) {
      // 检测是否重复跳点导致多次进行播放对应操作，如果重复则拦截
      const d = new Date()
      const nowTime = d.getTime()
      console.log('checkDuplicateTime ** time', time)
      if (!this.lastTime) {
        // 未设置时间则不拦截
        this.lastTime = time
        this.nowTime = nowTime
        return false
      }
      console.log('checkDuplicateTime ** lastTime', this.nowTime - nowTime)
      if (this.nowTime - nowTime > -5000 && this.nowTime - nowTime < 5000) {
        // 进入该函数小于5秒时判断可能重复跳点
        const mLastTime = moment(this.lastTime)
        const mTime = moment(time)
        if (mLastTime === mTime) {
          // 当前跳点时间与上次跳点时间一样时则认为重复跳点
          return true
        }
        this.lastTime = time
        this.nowTime = nowTime
        return false
      } else {
        // 大于则正常跳点，更新上次跳点与当前进入函数时间
        this.lastTime = time
        this.nowTime = nowTime
        return false
      }
    },
    handleEnded(streamId) {
      // 播放下个视频时操作
      const obj = this.camerasObj[streamId]
      this.clearPlayInterval(streamId)
      if (obj.lastObj) {
        console.log('interval *** end')
        this.targetVideo(streamId, obj.lastObj.startTime)
      }
    },
    handleSearch() {
      for (const item of this.playCaremas) {
        if (item.cameraVisible) {
          this.$refs['ref-' + (item.id || item.streamId)] &&
            this.$refs['ref-' + (item.id || item.streamId)][0].trunSyncPlayOff()
        }
      }
      this.isJumpInit = false
    }
  },
  beforeDestroy() {
    this.clearAllPlayInterval()
  }
}
</script>

<style></style>
